<template>
  <div>
    <h3 ref="intro">
      Straight Line
    </h3>
    <p>
      In geometry, a straight line is a line that is not curved. Straight line is one of the simplest shapes in geometry.<br><br>
      All the points lying on a straight line are aligned in one direction. Thus, you can go from one point to another without changing the direction.

      A straight line passing through two ponits is the shortest path between those two points.
    </p>
    <h3 ref="types">
      Drawing a Straight Line
    </h3>
    <br>
    <p> Drawing a straight line is one of the easiest constructions in geometry. Below are the steps to draw a straight line. </p>
    <ul class="a mx-3">
      <li>
        On a sheet of paper, mark two points that are some distance apart from each other (meaning two points are not coincident).
      </li>
      <li>
        Take a ruler and place it on the sheet of paper such that the two points lie along the straight edge of the ruler.
      </li>
      <li>
        Connect the two points with a pen or pencil, and you will have straight line.
      </li>
    </ul>
    <br>
    <h3 ref="pg">
      MagicGraph | Straight Line through Two Points
    </h3>
    <br>
    <p> This MagicGraph provides a visual interactive illustrator using which you can draw a straight line.</p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Tools',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Draw a Straight Line';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is Symmetry?', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Common Geometric Tools', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    this.$store.commit('navigation/toggleshowhome', false);
    this.$store.commit('navigation/replaceMath', true);
    this.$store.commit('navigation/replaceLeftArrow', true);
    this.$store.commit('navigation/replaceModule', true);
    this.$store.commit('navigation/replaceSample', true);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Draw a Line',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about Thales Theorem'}
                ]
        }
   },
}
</script>
